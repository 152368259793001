.status {
    display: inline-flex;
    border-radius: 10px;
    align-items: center;

    padding: 4px 10px;
    gap: 6px;
    width: fit-content;
    color: #4A4A68;
}

.status[data-normal="true"] {
    background: var(--bg-warning);
}

.status[data-active="true"] {
    background: var(--bg-success);
}

.status[data-failure="true"] {
    background: var(--bg-danger);
}

.circle {
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.circle[data-normal="true"] {
    background: #4A4A68;
}

.circle[data-active="true"] {
    background: var(--text-success);
}

.circle[data-failure="true"] {
    background: var(--text-danger);
}
