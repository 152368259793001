.flexWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;

}

.wrapper {
    background: var(--text-danger);
    padding: 14px 20px;
    display: inline-flex;
    gap: 1em;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    color: #fff;

    max-width: 420px;
    width: 100%;
}