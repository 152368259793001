.wrapper {
  width: 100%;
  position: relative;
  user-select: none;

}

.slides {
  display: inline-flex;
  gap: 20px;
  height: fit-content;
  width: 100%;

  transition-property: all;
  transition-timing-function: linear;
  transition-duration: 400ms;
  will-change: scroll-position;
  margin-left: 20px;
}

.tooltip {
  position: absolute;
  right: 20px;
  bottom: 64px;
  width: 48px;
  height: 48px;
  z-index: 12;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  animation-name: tooltip;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: all ease .3s;
  opacity: 1;
  pointer-events: none;
}

.tooltip[data-visible="false"] {
  opacity: 0 !important;
  animation-name: tooltipDisappear;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.slide {
  flex-shrink: 0;
  width: calc(80% - 20px);
  aspect-ratio: 1;
}

.scrollContent {
  width: 100%;
  overflow: hidden;
}

.arrowLeft {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 50%;
  transform: rotate(180deg) translateY(50%) translateX(calc(100% + 1em));
}

.arrowRight {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(calc(100% + 1em)) translateY(-50%);
}

.points {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 25px;
}

.point {
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: var(--gray);
  cursor: pointer;
}

.point[data-selected='true'] {
  background: var(--text-danger);
}

@media screen and (min-width: 521px) {
  .tooltip {
    display: none;
    animation: none !important;
  }
}

@media screen and (max-width: 520px) {
  .arrowLeft {
    display: none;
  }

  .arrowRight {
    display: none;
  }
}

@keyframes tooltip {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes tooltipDisappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
