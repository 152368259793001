.campaign {
  position: relative;
  width: 100%;
  max-height: 335px;
}

.campaignTitle {
  position: absolute;
  bottom: 16px;
  left: 22px;
  font-weight: 700;
  z-index: 12;
}

.campaignTitleWithSubtitle {
  height: 25px;
  position: absolute;
  bottom: 42px;
  left: 22px;
  font-weight: 700;
  z-index: 12;
  text-transform: uppercase;
}

.campaignSubtitle {
  height: 25px;
  position: absolute;
  bottom: 16px;
  left: 22px;
  z-index: 12;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.card {
  position: relative;
  width: 100%;
  padding: 2px;
  aspect-ratio: 1;
  min-height: 272px;
  filter: drop-shadow(0px 5px 15px rgba(44, 92, 250, 0.1));
}


.campaignStatus {
  position: absolute;
  top: 0;
  left: 2px;
  transform: translateY(-50%);
  height: calc(var(--input-height) / 2);
  color: #fff;
  background: var(--text-danger);
  padding: 2px 12px 2px 9px;
  border-radius: 8px 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  z-index: 12;
}
